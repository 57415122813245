// Load Bootstrap JS
import bootstrap from 'bootstrap'

// Load Styles
import '../scss/main.scss';

import $ from 'jquery';

// App code
console.log(`Hello ${process.env.HELLO}`);


$( document ).ready(function() {
    
    setTimeout(function () {
      $("#subtitle").fadeIn(600).animate({
          opacity: 1.0,
          display:"toggle",
     }, { duration: 500, queue: false });
    }, 2000); 
    setTimeout(function () {
      $(".btn-oz").fadeIn(600).animate({
          opacity: 1.0,
          
          display:"toggle",
     }, { duration: 500, queue: false });
    }, 1500); 
    
  });
  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();
  
    if (scroll >= 300) {
        $(".clearHeader").addClass("darkHeader");
    } else {
        $(".clearHeader").removeClass("darkHeader");
    }
  });
  
  
    
  $.fn.animate_Text = function() {
    var string = this.text();
    return this.each(function(){
     var $this = $(this);
     $this.html(string.replace(/./g, '<span class="new">$&</span>'));
     $this.find('span.new').each(function(i, el){
      setTimeout(function(){ $(el).addClass('div_opacity'); }, 40 * i);
     });
    });
   };
   $('#title').show();
   $('#title').animate_Text();
  
  
   function onEntry(entry) {
    entry.forEach(change => {
      if (change.isIntersecting) {
        change.target.classList.add('element-show');
      }
    });
  }
  let options = { threshold: [0.1] };
  let observer = new IntersectionObserver(onEntry, options);
  let elements = document.querySelectorAll('.element-animation');
  for (let elm of elements) {
    observer.observe(elm);
  }


  